
.modalContent {
  @apply tw-relative sm:tw-min-w-[300px] sm:tw-min-h-[300px] tw-bg-white tw-rounded;
  @apply tw-p-5 tw-max-h-[80%] tw-overflow-auto;

  &.md {
    @apply tw-w-4/5 sm:tw-w-[520px] tw-text-inherit;
  }

  &.lg {
    @apply tw-w-11/12 md:tw-w-4/5  xl:tw-w-3/4;
  }
}

.modalOverlay {
  @apply tw-z-[9999] tw-fixed tw-inset-0;
  @apply tw-flex tw-items-center tw-justify-center;
  font-family: var(--secondary-font-family);
  background-color: #323232e6;
}