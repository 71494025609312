.print-summary{
  display: none;
  font-family: 'Neue Haas Unica Pro';
  font-size: 12px;
  position: relative;

  .print-summary-container{
    & > div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 0;
  
      & > p:nth-of-type(2) {
        justify-self: end;
      }

      & > p { 
        margin-bottom: 0;
      }

    }
  }
}

  .print-window .print-summary{
    display: block;
  }

  @page {
    size: A4;
    margin: 0;
  }