
.min-w-18 {
  min-width: 4.5rem;
}

.selected-border:before {
  border: 1px var(--brand-color) dashed !important;
  animation: 3s loader infinite;
  position: absolute;
  width: 72px;
  height: 72px;
  right: calc(50% - 36px);
  @media screen and (min-width: 640px) {
    height: 100%;
    width: 100%;
    left: 0;
  }
  padding: 4px;
  border-radius: 50%;
  top: -1px;
  z-index: -1;
  content: '';
}

.selected-border-square:before {
  border: 1px var(--brand-color) dashed !important;
  animation: 2s squareLoader infinite;
  border-radius: 4px;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: -2px;
  top: -2px;
}

@keyframes loader {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(90deg) scale(1.1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  75% {
    transform: rotate(270deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}


@keyframes squareLoader {
  0% {
    padding: 1px;
    margin: 3px;
  }
  10% {
    padding: 2px;
    margin: 2px;
  }
  20% {
    padding: 3px;
    margin: 1px;
  }
  30% {
    padding: 2px;
    margin: 2px;
  }
  40% {
    padding: 1px;
    margin: 3px;
  }
  50% {
    padding: 2px;
    margin: 2px;
  }
  60% {
    padding: 3px;
    margin: 1px;
  }
  70% {
    padding: 2px;
    margin: 2px;
  }
  80% {
    padding: 1px;
    margin: 3px;
  }
  90% {
    padding: 2px;
    margin: 2px;
  }
  100% {
    padding: 3px;
    margin: 1px;
  }
}

.moreInfo-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Ensures the label takes the full width of its container */
  height: 50%;
  transition: .5s ease;
  text-decoration: underline;
  border-bottom-left-radius: 100px; /* Half of the height */
  border-bottom-right-radius: 100px; /* Half of the height */

  font-family: var(--secondary-font-family);;
  color: white;
}