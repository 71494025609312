html { 
  font-size: var(--font-size);
}

body {
  margin: 0;
  font-family: var(--primary-font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/HKGrotesk/HKGrotesk-Regular.eot');
  src: url('./assets/fonts/HKGrotesk/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HKGrotesk/HKGrotesk-Regular.woff2') format('woff2'), url('./assets/fonts/HKGrotesk/HKGrotesk-Regular.woff') format('woff'), url('./assets/fonts/HKGrotesk/HKGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sabon LT Pro';
  src: url('./assets/fonts/Sabon/Sabon.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lucida Calligraphy';
  src: url('./assets/fonts/lucida-calligraphy/lucida-calligraphy.eot');
  src: url('./assets/fonts/lucida-calligraphy/lucida-calligraphy.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/lucida-calligraphy/lucida-calligraphy.woff2') format('woff2'), url('./assets/fonts/lucida-calligraphy/lucida-calligraphy.woff') format('woff'), url('./assets/fonts/lucida-calligraphy/lucida-calligraphy.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bradley Handwriting';
  src: url('./assets/fonts/bradley-handwriting/bradley-handwriting.eot');
  src: url('./assets/fonts/bradley-handwriting/bradley-handwriting.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/bradley-handwriting/bradley-handwriting.woff2') format('woff2'), url('./assets/fonts/bradley-handwriting/bradley-handwriting.woff') format('woff'), url('./assets/fonts/bradley-handwriting/bradley-handwriting.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'French Script';
  src: url('./assets/fonts/french-script/french-script.eot');
  src: url('./assets/fonts/french-script/french-script.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/french-script/french-script.woff2') format('woff2'), url('./assets/fonts/french-script/french-script.woff') format('woff'), url('./assets/fonts/french-script/french-script.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kells SD';
  src: url('./assets/fonts/kells-sd/kells-sd.eot');
  src: url('./assets/fonts/kells-sd/kells-sd.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/kells-sd/kells-sd.woff') format('woff'), url('./assets/fonts/kells-sd/kells-sd.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lucia BT';
  src: url('./assets/fonts/lucia-bt/lucia-bt.eot');
  src: url('./assets/fonts/lucia-bt/lucia-bt.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/lucia-bt/lucia-bt.woff2') format('woff2'), url('./assets/fonts/lucia-bt/lucia-bt.woff') format('woff'), url('./assets/fonts/lucia-bt/lucia-bt.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tempus Sans';
  src: url('./assets/fonts/tempus-sans/tempus-sans.eot');
  src: url('./assets/fonts/tempus-sans/tempus-sans.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/tempus-sans/tempus-sans.woff2') format('woff2'), url('./assets/fonts/tempus-sans/tempus-sans.woff') format('woff'), url('./assets/fonts/tempus-sans/tempus-sans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TimesNewRomanPro';
  src: url('./assets/fonts/times-new-roman-pro/times-new-roman-pro.eot');
  src: url('./assets/fonts/times-new-roman-pro/times-new-roman-pro.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/times-new-roman-pro/times-new-roman-pro.woff2') format('woff2'), url('./assets/fonts/times-new-roman-pro/times-new-roman-pro.woff') format('woff'), url('./assets/fonts/times-new-roman-pro/times-new-roman-pro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Vladimir Script';
  src: url('./assets/fonts/vladimir-script/vladimir-script.eot');
  src: url('./assets/fonts/vladimir-script/vladimir-script.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/vladimir-script/vladimir-script.woff2') format('woff2'), url('./assets/fonts/vladimir-script/vladimir-script.woff') format('woff'), url('./assets/fonts/vladimir-script/vladimir-script.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Old London';
  src: url('./assets/fonts/old-london/old-london.eot');
  src: url('./assets/fonts/old-london/old-london.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/old-london/old-london.woff') format('woff'), url('./assets/fonts/old-london/old-london.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url("./assets/fonts/Neue Haas Unica/NeueHaasUnicaPro-Regular.ttf");
}

@font-face {
  font-family: 'Reckless';
  src: url("./assets/fonts/Reckless/Reckless-Regular.ttf");
}

@font-face {
  font-family: "Tenor Sans";
  src: url("./assets/fonts/tenor-sans/tenor-sans.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.arial {
  font-family: 'Arial' !important;
}

.lucida-calligraphy {
  font-family: 'Lucida Calligraphy' !important;
}

.lucia-bt {
  font-family: 'Lucia BT' !important;
}

.french-script {
  font-family: 'French Script' !important;
}

.times-new-roman {
  font-family: 'TimesNewRomanPro' !important;
}

.vladimir-script {
  font-family: 'Vladimir Script' !important;
}

.tempus-sans {
  font-family: 'Tempus Sans' !important;
}

.old-london {
  font-family: 'Old London' !important;
}

.kells-sd {
  font-family: 'Kells SD' !important;
}

/* Variables declaration starts */

:root {
  --font-size: 16px;
  --semi-bold: 600;

  --primary-font-family: "HK Grotesk";
  --secondary-font-family: "HK Grotesk";

  --brand-color: #095E4D;
  --brand-logo: url("./assets/images/LB_Logoblue.png");

  --primary-color: #000000;
  --secondary-color: #323232;
  --tertiary-color: #ffffff;

  --primary-bg-color: #f7f6f4;
  --secondary-bg-color: #ffffff;

  --primary-border-color: #e0baad;
  --secondary-border-color: #e9ecef;

  --primary-border-radius: 8px;
  --secondary-border-radius: 4px;
  --tertiary-border-radius: 9999px;

  --rc-box-tab-case: capitalize;

  --sidebar-order:0;
}

/* Variables declaration ends */

.primary-font-family {
  font-family: var(--primary-font-family);
}

.secondary-font-family {
  font-family: var(--secondary-font-family);
}

.app-container {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh));

  .main_container {
    overflow: hidden;
    color: var(--primary-color);
  }
}


.gallery_container {
  @media screen and (max-width: 1023px) {
    height: 35%; // for xs, sm, md have 30%
  }
  @media screen and (min-width: 1024px) {
    height: 40%; // for lg and above have 40%
  }
}

.customizer_container {
  .swatch-filter-border{
    @media screen and (min-width: 640px) {
      border-top: 2px solid var(--primary-border-color);
      position: relative;
      z-index: 3;
    }
  }
}

.customizer_container.summaryInfo {
  @media screen and (min-width: 1024px) {
    height: 90%;
  }
}

.width-20 {
  width: 20px;
}

.brand-logo {
  content: var(--brand-logo);
  max-height: 85px;
  display: inline-block;
}
