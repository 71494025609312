/* Style the accordion section */
.accordion-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #fff;
    cursor: pointer;
    padding: 12px;
    display: flex;
    align-items: center;
    transition: background-color 0.6s ease;
}

/* Style the accordion content title */
.accordion-title {
    font-weight: 600;
    text-align: left;
    padding-left: 10px;
}

/* Style the accordion chevron icon */
.accordion-icon {
    transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
    background-color: #fff;
    overflow: auto;
    transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion-text {
    font-weight: 400;
    font-size: 14px;
    padding: 18px 5px 18px 28px;
    display: inline-block;
}
.accordion-text ul{
    margin-left: 15px;
    margin-bottom: 10px;
}
.accordion-text ul li{
    margin-bottom: 8px;
}
