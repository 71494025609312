.loading-mask {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background-color: #fff;
  opacity: 0.5;
  background-position: center;
  background-position-y: 25%;
  background-repeat: no-repeat;
}

@media screen and (max-width:420px) {
  .loading-mask{
  background-position-y: center;
  }
}
/* Add dot styles**/
.image-gallery:not(.fullscreen-modal) .image-gallery-swipe {
  height: calc(100% - 18px);
}

.image-gallery .image-gallery-bullets .image-gallery-bullet {
  padding: 3px;
  background-color: #fff;
  border: 1px solid #c2c2c2;
  box-shadow: none;
}

/* on fullscreen show a bit bigger bullets */
.image-gallery.fullscreen-modal .image-gallery-bullets .image-gallery-bullet {
  padding: 5px;
}

.image-gallery .image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery .image-gallery-bullets .image-gallery-bullet.active {
  transform: none;
  background-color: #c2c2c2;
  border: 1px solid #c2c2c2;
}

.image-gallery .image-gallery-bullets .image-gallery-bullet.active:hover {
  background-color: #c2c2c2;
}

.image-gallery .image-gallery-bullets {
  margin-top: 8px;
  position: static;
}

.image-gallery.fullscreen-modal .image-gallery-bullets {
  position: absolute;
  bottom: 70px;
}
