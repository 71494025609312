.size-info-tooltip {
  font-family: 'reckless';
}

.product_details_container {
  .faq-content {
    font-weight: 400 !important;
    font-size: 18px;
  }

  .customizer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddc77c;
    color: #003053;
    font-size: 15px;
    line-height: 23px;
    font-weight: 500;
    padding: 8px 36px;
    text-transform: none;
    border-radius: 9999px;
    border: none;
    font-size: 22px;
  }

  .customizer-btn:hover,
  .customizer-btn:focus {
    background-color: #ddc77c;
    color: #003053;
    border: none;
    outline: none;
    box-shadow: none;
  }

  .rangeslider {
    background-color: #efefef;
    height: 5px;
}

  .rangeslider .rangeslider__fill {
    background-color: #002f53;
}

  .rangeslider-horizontal .rangeslider__handle:after {
    width: 15px;
    height: 15px;
    top: 4px;
    left: 4px;
}

  .rangeslider-horizontal .rangeslider__handle {
    width: 25px;
    height: 25px;
}

.loading .rangeslider-horizontal .rangeslider__handle:before {
    border: 1px #002f53 dashed !important;
    width: 30px;
    height: 30px;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: calc(50% - 15px);
    right: calc(50% - 16px);
    animation: 3s loader infinite;
}


@media screen and (max-width: 1024px) {
    .loading .rangeslider-horizontal .rangeslider__handle:before {
        right: calc(50% - 15px);
    }
}

.slider__container .rangeslider__handle-tooltip {
  width: 70px;
  display: flex;
  justify-content: center;
}

  .slider__container {
    display: flex;
    justify-content: center;
    position: relative;
    height: 67px;
    top: 0;
  }

  .slider__range {
    position: absolute;
    width: calc(100% - 96px);
    left: unset;
    top: -10px;
  }

  .slider__container span.decrement {
    display: block;
    float: left;
    position: absolute;
    border-radius: 50%;
    background: #c4c4c4;
    width: 22px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    left: 8px;
  }

  .slider__container span.increment {
    right: 0;
    cursor: pointer;
    display: block;
    float: left;
    position: absolute;
    border-radius: 50%;
    background: #c4c4c4;
    width: 22px;
    text-align: center;
    font-size: 14px;
}

.rangeslider {
  background-color: #efefef;
  height: 5px !important;
}

.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}

  .slider__container span.increment {
    right: 8px;
  }

  .slider__container .rangeslider__handle-tooltip {
    width: 70px;
    display: flex;
    justify-content: center;
  }

  .rangeslider .rangeslider__handle {
    border: 1px solid #003053;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    background-color: #2A77A7;
  }

  #info-container {
    .swiper-slide{
      width: 100% !important;
      height: 100% !important;
      transform: scale(1) !important;
    };

    .swiper-pagination{
      bottom: 24px;
    }

    .info-slide {
      background: #2A77A7;
      color: #FFFFFF;
      margin: 0 !important;
    }
    .swiper-button-prev, .swiper-button-next {
      color: #DDC77C;
      &::after {
        font-size: 12px !important;
      }
      // For sm devices between 640 and 
      @media screen and (min-width: 640px) and (max-width: 767px) {
        top: 57%;
      }
      // For xs devices less than 640px
      @media screen and (max-width: 639px) {
        top: 55.5%
      }
    }
  /* 
    By default it loads 10px on left and right. 
    As per new design, it needs to load right menu behind 10px of 50% 
  */
    @media screen and (min-width: 768px) {
      .swiper-button-next {
        right: calc(50% + 10px);
      }
    }
  /* 
    By default it loads 10px on left and right. 
    As per new design, it needs to load right menu behind 5% of 50% 
  */
    @media screen and (min-width: 1280px) {
      .swiper-button-prev {
        left: 5%;
      }
      .swiper-button-next {
        right: 55%;
      }
    }

    .swiper-pagination {
      display: flex;
      width: 100%;
      justify-content: center;
      @media screen and (min-width: 768px) {
        width: 50%;
      }
      .swiper-pagination-bullet {
        background: #ffffff;
        opacity: 1;
      }
  
      .swiper-pagination-bullet-active {
        background: #DDC77C;
      }
    }
  }


  @media screen and (max-width: 1023px) {
    .customizer-btn {
      padding: 8px 20px;
    }
  }
  
  @media screen and (max-width: 640px) {
    .customizer-btn {
      font-size: 16px;
      font-weight: 700;
    }
  }
  
  @media screen and (max-width: 420px) {
    .customizer-btn {
      padding: 8px 12px;
      font-size: 14px;
      width: 100%;
    }
  }

}


.pdp-summary-accordion {
  .accordion {
    background-color: #f4fbff;
    padding-left: 0;
  }

  .accordion-title {
    font-weight: 700;
    font-size: 15px;
    padding-left: 0;
    margin-right: 6px;
  }

  .accordion-icon {
    transform: rotateZ(90deg) rotateY(0deg);
  }

  .accordion-icon.rotate {
    transform: rotateZ(90deg) rotateY(180deg);
  }

  .accordion-content::-webkit-scrollbar {
    display: none;
  }

  .accordion-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}


/*NOTE: If any updates in this then do the same on src/components/StaticProduct/styles.css as well. */
.pdp-summary-accordion p[content~='price_multiplier'],
.pdp-summary-accordion p[content~='hashed_url'],
.pdp-summary-accordion p[content~='description'],
.pdp-summary-accordion p[content~='pictures'],
.pdp-summary-accordion p[content~='ring_options_url'],
.pdp-summary-accordion p[content~='price'],
.pdp-summary-accordion p[content~='product_type'],
.pdp-summary-accordion p[content~='image_url'],
.pdp-summary-accordion p[content~='is_favorite'] {
    display: none;
}

i.arrow-golden {
  color: #dcc67d;
}

.tooltip-inner{
  max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
    font-size: 14px;
}