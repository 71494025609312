.swiper-slide {
  font-family: var(--secondary-font-family);
  width: 52px !important;
  height: 52px !important;
  border: 1px solid rgba(196, 196, 196, 0.5);
  background: #ffffff;
  color: #c4c4c4;
  border-radius: 50%;
  margin: 10px 0px;
  transform: scale(0.8) !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

.active-slide {
  transform: scale(1.1) !important;
  color: #000000;
  border: 2px solid var(--primary-border-color);
}
