.summary .summary-container > div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 0;
}

.summary .summary-container > div > p:nth-of-type(2) {
  justify-self: end;
}

.summary-pdp {
  p {
    margin-bottom: 0;
  }
}