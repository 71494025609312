.image-gallery, .image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-swipe, .image-gallery-slide {
  height: 100%
}

.image-gallery-slide-wrapper.left {
  width: 100% !important;
}

.image-gallery-swipe, .image-gallery-slides {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery-left-nav {
  left: 8%;
}

.image-gallery-right-nav {
  right: 8%;
}

.image-gallery-slides {
  height: 100%;
  max-width: 80%;
}

.image-gallery-thumbnails {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  margin-left: 12px;
}

.image-gallery-thumbnail:hover {
  border: 1px solid #337ab7 !important;
}

.image-gallery-thumbnail.active {
  border: 1px solid #E5E5E5 !important;
}

.image-gallery-thumbnails-wrapper.left {
  width: 70px;
}

.image-gallery-thumbnails-container > .image-gallery-thumbnail {
  margin: 10px 0px !important;
  width: 70px;
}

.image-gallery-content.fullscreen {
  background: #ffffff !important;
}

.image-gallery-fullscreen-button {
  bottom: 10% !important;
}

.fullscreen .image-gallery-slides {
  width: 50%;
}

.fullscreen .image-gallery-left-nav {
  left: 15%;
}

.fullscreen .image-gallery-right-nav {
  right: 15%;
}

.image-gallery-svg, .image-gallery-icon:hover .image-gallery-svg {
  transform: scale(.60);
  opacity: 60%;
}

.fullscreen .image-gallery-image {
  max-height: 82% !important;
}

@media screen and (max-width: 480px) {
  .image-gallery-thumbnails-wrapper.left {
      display: none;
  }
}

/**
 * Hide left and right nav for mobile
 */
@media screen and (max-width: 480px) {
  .image-gallery-left-nav, .image-gallery-right-nav {
    display: none;
  }
  .banner-long {
    display: none;
  }
}



