.desktop-sidebar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 3;
  order: var(--sidebar-order);

  .sidebar-tab {
    min-height: 50px;
  }
  .active + .sidebar-tab {
    border-top: none;
  }

  .active {
    position: relative;
    &::after {
      height: 100%;
      position: absolute;
      width: 0;
      display: block;
      border: 2px solid white;
      content: '';
    }
  }

  #active-v-border {
    position: absolute;
    top: 100%; //  will change (bottom: 100%; top: unset) based on selection
    // right: -2px; // moved little right to hide shadow
    min-height: 0px;
    border-right: 2px solid var(--primary-border-color);
    z-index: 3;
  }

  #active-h-border {
    position: absolute;
    bottom: 0; //  will change (top: 0; bottom: unset) based on selection
    // left: 4%;
    width: calc(96% + 2px); // add border additional 1px to hide shadow
    border-top: 2px solid var(--primary-border-color);
    border-top-left-radius: 10px;
    z-index: 3;
  }


  &.left {
    padding-left: 12px;

    .active {
      border-radius: 10px 0px 0px 10px;
      &::after {
        right: -2px;
      }
    }

    #active-v-border {
      right: 0px; // moved little right to hide shadow
    }

    #active-h-border {
      left: 4%;
    }
  }

  &.right {
    padding-right: 12px;

    .active {
      border-radius: 0px 10px 10px 0px;
      &::after {
        left: -2px;
      }
    }

    #active-v-border {
      left: 0px; // moved little right to hide shadow
    }

    #active-h-border {
      right: 4%;
    }
  }
}

.mobile-sidebar {
  @apply -tw-m-5;
  .sidebar-tab {
    @apply tw-flex tw-justify-between tw-px-3 tw-min-h-[3rem] tw-cursor-pointer tw-text-xs md:tw-text-base;
    @apply tw-border-b tw-border-solid tw-border-gray-200;

    .sidebar-title {
      @apply tw-text-primary-color tw-text-sm tw-font-bold;
    }

    .sidebar-value {
      @apply tw-text-xs tw-text-center tw-text-blue-900 tw-text-opacity-80;
      color: #003053CC;
    }

    .arrow-icon {
      @apply tw-absolute tw-top-0.5 -tw-left-4;
    }
  }
}
