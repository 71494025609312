.rc-tabs-nav {
  justify-content: center;
}

.rc-tabs-nav-wrap {
  flex: none; /* it helps to make all tab center*/
  width: 100%;
}
.rc-tabs-content-holder .rc-tabs-nav-wrap {
  padding-top: 8px;
}

/* For mobile devices align from left*/

@media screen and (min-width: 250px) and (max-width: 640px) {
  .rc-tabs-nav {
    justify-content: flex-start;
  }
  .rc-tabs-nav-wrap {
    flex: auto;
  }
  /* For signle tab align center*/
  .single-tab-container > .rc-tabs-nav > .rc-tabs-nav-wrap {
    justify-content: center;
  }
}

.rc-tabs-tab {
  background: transparent !important;
}

.rc-tabs-ink-bar {
  background: transparent !important;
}

.rc-tabs-nav-operations {
  display: none !important;
}

.rc-single-tab {
  font-family: var(--primary-font-family);
  font-weight: var(--semi-bold);
  font-size: 20px;
  /* color: #323232; */
  color: var(--primary-color);
  padding: 10px 0px;
  width: 65vw;
  text-align: center;
}

.rc-single-tab .text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

@media screen and (min-width: 640px) and (max-width: 767px) {
  .rc-single-tab {
    width: 50vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .rc-single-tab {
    width: 40vw;
  }
}

@media screen and (min-width: 1024px) {
  .rc-single-tab {
    width: 30vw;
  }
}

.rc-tabs-tab-btn {
  height: 100%;
  outline: none;
}
.rc-tabs-tab-btn:active {
  outline: none;
}

.rc-box {
  font-family: var(--primary-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* color: #323232; */
  /* color: #000000; */
  color: var(--primary-color);
  padding: 10px 16px;
  margin: 0px 4px;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-box.rc-box-active {
  font-weight: var(--semi-bold);
  background: #FFFFFF;
  color: var(--primary-color);
  position: relative;
  border: 1px solid #c4c4c4;
  /* border: 2px solid var(--primary-border-color); */
  border-bottom: none;
  border-radius: var(--primary-border-radius) var(--primary-border-radius) 0px 0px;
  box-shadow: 0px -3px 4px 0px rgb(0 0 0 / 25%);
  bottom: 0px;
  bottom: -2px;
}

.rc-box img {
  height: 17px;
}

.rc-tabs-nav-list {
  /* For work around of tab scroll set width more than 150 */
  width: 200%;
  border-bottom: 2px solid var(--primary-border-color);
  justify-content: center;
}

.rc-underline {
  font-family: var(--primary-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* color: #323232; */
  color: #000000;
  padding: 12px 6px;
  margin-left: 8px;
  height: 44px;
}

.rc-underline.rc-underline-active {
  font-weight: 600;
  border-bottom: 3px solid #323232;
}

.rc-tabs,
.rc-tabs-content,
.rc-tabs-tabpane {
  height: 100%;
  min-height: 100%;
}

.rc-tabs-content-holder {
  height: 0;
}

.rc-box-tab-case {
  text-transform: var(--rc-box-tab-case);
}

@media screen and (max-width: 639px) {
  .rc-box.rc-box-active {
    bottom: -3px;
  }
  .rc-tabs-nav-list {
    border: none;
  }
  .rc-tabs-content-holder .rc-tabs-nav-list {
    border-bottom: 2px solid var(--primary-border-color);
  }
  .rc-tabs-nav {
    background-color: var(--primary-bg-color);
  }
  .rc-tabs-content-holder .rc-tabs-nav-wrap {
    padding-top: 0;
  }
}

@media screen and (min-width: 640px) {
  .rc-tabs-nav {
    background-color: white;
  }
  .single-tab-container > .rc-tabs-nav > .rc-tabs-nav-wrap > .rc-tabs-nav-list {
      display: none;
  }
}

.rc-tabs-nav-measure-ping-right:after,
.rc-tabs-nav-wrap-ping-right:after {
  border-right: 2px solid var(--brand-color) !important;
}

.rc-tabs-nav-measure-ping-left:before,
.rc-tabs-nav-wrap-ping-left:before {
  border-left: 2px solid var(--brand-color) !important;
}
